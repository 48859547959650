// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-apply-page-js": () => import("/opt/build/repo/src/templates/apply-page.js" /* webpackChunkName: "component---src-templates-apply-page-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-journal-page-js": () => import("/opt/build/repo/src/templates/journal-page.js" /* webpackChunkName: "component---src-templates-journal-page-js" */),
  "component---src-templates-location-page-js": () => import("/opt/build/repo/src/templates/location-page.js" /* webpackChunkName: "component---src-templates-location-page-js" */),
  "component---src-templates-partners-page-js": () => import("/opt/build/repo/src/templates/partners-page.js" /* webpackChunkName: "component---src-templates-partners-page-js" */),
  "component---src-templates-pre-seed-page-js": () => import("/opt/build/repo/src/templates/pre-seed-page.js" /* webpackChunkName: "component---src-templates-pre-seed-page-js" */),
  "component---src-templates-seed-page-js": () => import("/opt/build/repo/src/templates/seed-page.js" /* webpackChunkName: "component---src-templates-seed-page-js" */),
  "component---src-templates-legal-page-js": () => import("/opt/build/repo/src/templates/legal-page.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-team-page-js": () => import("/opt/build/repo/src/templates/team-page.js" /* webpackChunkName: "component---src-templates-team-page-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-tags-index-js": () => import("/opt/build/repo/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

